import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { CardDetailModel, UpdateSubscriptionModel } from "../../models";

@Injectable({
    providedIn: 'root',
})

export class SubscriptionService {

    constructor(private http: HttpClient) { }

    private apiEndPoint = environment.apiBaseUrl + 'api/subscription/';

    getDetailBySubscriptionId(subscriptionId: string) {
        return this.http.get(this.apiEndPoint + 'get-detail-by-subscription-id/' + subscriptionId);
    }

    getPlans() {
        return this.http.get(this.apiEndPoint + `get-plans`);
    }
    
    getCardDetails() {
        return this.http.get(this.apiEndPoint + 'get-card');
    }

    getUpdateEstimate(planId: string, quantity: number, groupId: any) {
        return this.http.get(this.apiEndPoint + `get-update-estimate/${planId}/${quantity}/${groupId}`);
    }

    updateSubscription(model: UpdateSubscriptionModel) {
        return this.http.post(this.apiEndPoint + 'update-subscription', model);
    }

    getPlansWithSubscribedPlan(subscribedPlanId: string, periodUnit: string) {
        return this.http.get(this.apiEndPoint + `get-plans/${subscribedPlanId}/${periodUnit}`);
    }

    updateCardDetails(model: CardDetailModel) {
        return this.http.post(this.apiEndPoint + 'update-card', model);
    }
}