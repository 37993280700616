import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MediaAppService {

    private apiEndPoint = environment.apiBaseUrl + 'api/mediaApp/';

    constructor(private http: HttpClient) { }

    getList() {
        return this.http.get(this.apiEndPoint + 'list');
    }
}
