export const environment = {
    production: true,
    apiBaseUrl: 'https://displaynow-api-dev.aspcore.net/',
    presenterAppUrl: 'https://displaynow-presenter-dev.aspcore.net/',
    eventStreamerBaseUrl: 'https://displaynow-eventstreamer-dev.aspcore.net/',
    chargebeeSiteName: 'displaynow-test',
    azureAccount: 'displaynowmediadev',
    azureContainerBaseUrl: 'https://media-dev.displaynow.io',
    azureContainers: {
        userMediaAppImage: 'image-media',
        userMediaAppPdf: 'pdf-media',
        userMediaAppVideo: 'video-media',
        remotePreviewImage: 'remote-preview',
        shared: 'shared',
        canvaMedia: 'canva-media'
    },
    azureAdB2C: {
        clientId: 'aa2961ec-6623-47f3-ba7c-7e5ea62bf4c5',
        redirectUri: 'https://displaynow-web-v2-dev.aspcore.net/account/login',
    },
    googleApi: {
        signin_client_id: '173856016669-3pn0n2lsjui8g4qh80u7jd1lceoat0ke.apps.googleusercontent.com',
        signin_scope: ['profile', 'email'].join(' '),
        client_id: '14260373170-mqp9ep9efno1d8m6ccrf58htscbb080u.apps.googleusercontent.com',
        scope: ['profile', 'email', 'https://www.googleapis.com/auth/drive.readonly'].join(' '),
        key: 'AIzaSyDkQIFkfmME0Z-HvmKhP6w3HnPsmfmyDzY',
        youtubeSearchBaseUrl: 'https://www.googleapis.com/youtube/v3',
        youtubeSearchMaxResult: 3
    },
    aesBase64Cryptography: {
        passPhrase: '63111C2D72A805A6143DE6995615C8F2',
        secret: 'E58F651CF25E5'
    },
    canva: {
        authUrl: 'https://canva.com/apps/configured',
        redirectUrl: 'https://www.canva.com/design',
        apiKey: 'jMzDUgRaxHFEHRQGrBlRCFzG7BzKJi9BpSKOTZ0XyY4='
    },
    vimeo: {
        authorizationEndpoint: 'https://api.vimeo.com/oauth/authorize',
        redirectUri: 'https://displaynow-web-dev.aspcore.net',
        client_id: '8f117b5009c2d693d6adc1e40b4695079c9dff0e',
        scope: 'public private edit interact',
    },
    microsoftApi: {
        authorizationEndpoint: 'https://login.live.com/oauth20_authorize.srf',
        graphApiEndpoint: 'https://graph.microsoft.com/v1.0',
        scope: 'https://graph.microsoft.com/User.Read'
    }
};
