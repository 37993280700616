import { Injectable } from "@angular/core";
import { Constants, AppUtils } from "../../../helpers";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Router } from "@angular/router";

@Injectable()
export class BaseService {

    errorMessage: string;
    get constants() { return Constants }
    get appUtils() { return AppUtils }

    constructor(private notification: NzNotificationService, private router: Router) {

    }

    processErrorResponse(error: any): void {
        const errorTitle = 'Error!'; // Adjust as needed
        // Use NzNotificationService with clear error type

        if (error.status === 0) {
            this.errorMessage = "Unable to connect with API Gateway."
        } else if (error.status === 404) {
            this.errorMessage = "Something went wrong. Please try refreshing the page.";
        } else if (error.status === 500) {
            this.errorMessage = "Internal server error.";
        } else {
            this.errorMessage = error.error;
        }

        this.notification.error(errorTitle, this.errorMessage, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    info(message: string): void {
        const infoTitle = 'Info!'; // Adjust as needed
        this.notification.info(infoTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }



} 