import { NgModule } from "@angular/core";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { BaseService } from "./services";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NativeAutofillDetector } from "./directives/autofill.detector.directive";
import { ContentTextViewComponent } from "./content-text-view/content.text.view.component";


@NgModule({
    declarations: [
        NativeAutofillDetector,
        ContentTextViewComponent
    ],
    imports: [
        NzLayoutModule,
        NzMenuModule,
        NzGridModule,
        NzCardModule,
        NzBreadCrumbModule,
        NzInputModule,
        NzSelectModule,
        NzButtonModule,
        NzDropDownModule,
        NzCollapseModule,
        NzDividerModule,
        NzDrawerModule,
        NzTableModule,
        NzPageHeaderModule,
        NzToolTipModule,
        NzFormModule,
        NzIconModule, 
        NzSpinModule,
        NzCarouselModule,
        NzModalModule,
        NzTabsModule,
        NzCheckboxModule,
        NzStepsModule,
        NzSwitchModule,
        NzRadioModule
        
    ],
    exports: [
        NzLayoutModule,
        NzMenuModule,
        NzGridModule,
        NzCardModule,
        NzBreadCrumbModule,
        NzInputModule,
        NzSelectModule,
        NzButtonModule,
        NzDropDownModule,
        NzCollapseModule,
        NzDividerModule,
        NzDrawerModule,
        NzTableModule,
        NzPageHeaderModule,
        NzToolTipModule,
        NzIconModule,
        NzFormModule,
        NativeAutofillDetector,
        NzSpinModule,
        ContentTextViewComponent,
        NzCarouselModule,
        NzModalModule,
        NzTabsModule,
        NzCheckboxModule,
        NzStepsModule,
        NzSwitchModule,
        NzRadioModule
    ],
    providers: [
        BaseService
    ]
})
export class SharedModule { }