import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) { }

    private apiEndPoint = environment.apiBaseUrl + 'api/user/';

    getUserInfo() {
        return this.http.get(this.apiEndPoint + 'user-info');
    }

    getUpdatedToken() {
        return this.http.get(this.apiEndPoint + 'get-updated-token');
    }

    getFreshWidgetToken() {
        return this.http.get(this.apiEndPoint + `get-helpdesk-token`);
    }

    getHubSpotVisitorIdentificationToken() {
        return this.http.get(this.apiEndPoint + `get-hubspot-identification-token`);
    }

    isCanvaUserExists(canvaUserId: string) {
        return this.http.get(this.apiEndPoint + `canva-user-exists?canvaUserId=${canvaUserId}`);
    }

    updateCanvaUser(canvaUserId: string) {
        return this.http.post(this.apiEndPoint + `update-canva-user?canvaUserId=${canvaUserId}`, null);
    }
}
