import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';


import moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { Constants } from './app.constants';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AppUtils {

    constructor(private router: Router) { }

    public emailRegexPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
    public onBoardingSteps =
        [{
            name: 'Overview Of Setup',
            url: 'step1',
            params: {},
            nextUrl: 'step2'
        },
        {
            name: 'Buy Page',
            url: 'step2',
            params: {},
            nextUrl: 'step3'
        },
        {
            name: 'User Self-Setup',
            url: 'step3',
            nextUrl: '/display/manage'
        }];

    public getDateTimeUtc() {
        return moment().utc();
    }

    public getFormattedDateTimeUtc() {
        return moment().utc().format('MM/DD/yyyy HH:mm:ss');
    }

    getBaseOffsetDateTime(baseUtcOffset: string) {
        const dateTime = moment().utc().add(baseUtcOffset);
        return dateTime ? dateTime.format('MM/DD/yyyy HH:mm:ss') : '';
    }

    public getFormattedDate(date: string, format: string) {
        if (!format) {
            format = 'MM/DD/YYYY';
        }
        return date ? moment(date).format(format) : '';
    }

    public getFormattedTime(time: string, format: string) {
        if (!format) {
            format = 'HH:mm:ss';
        }
        return time ? moment(time).format(format) : '';
    }

    public getFormattedDateTime(date: string, format: string) {
        if (!format) {
            format = 'MM/DD/yyyy hh:mm A';
        }
        return date ? moment(date).format(format) : '';
    }

    public getDateTime(date: string, time: string) {
        const dateTime = moment(date).add(time);
        return dateTime ? dateTime.format('MM/DD/yyyy hh:mm A') : '';
    }

    public getFullDateTime() {
        const dateTime = moment();
        return dateTime ? dateTime.format('MM/DD/yyyy hh:mm A') : '';
    }

    public getUtcOffSet(offsetBase: string = null) {
        return !offsetBase
            ? moment().utcOffset()
            : moment().utcOffset(offsetBase);
    }

    public isUserAuthenticated() {
        return this.getAuthToken();
    }

    public getUserId() {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.id;
    }

    public getUserEmail() {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.email;
    }

    public getUserName() {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return `${user.firstName} ${user.lastName}`;
    }

    public getGroupId() {
        const selectedGroupId = localStorage.getItem(Constants.varSelectedGroupId);
        return selectedGroupId === null
            || selectedGroupId === undefined
            || selectedGroupId === ''
            ? null
            : Number(selectedGroupId);
    }

    public getGroupUserCount() {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.groupUserCount;
    }

    public getSubscriptionStatus() {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.subscriptionStatus;
    }

    public getUserInformation() {
        return JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
    }


    public setUserInformation(response) {
        localStorage.setItem(Constants.varUserInfo, this.encrypt(JSON.stringify(response)));
    }

    public isOnboardingStepFinished(): boolean {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        return this.onBoardingSteps.length === user.onboardingStep;
    }

    public getCurrentOnboardingUrl(): string {
        const user = JSON.parse(this.decrypt(localStorage.getItem(Constants.varUserInfo)));
        const activeStep = this.onBoardingSteps[user.onboardingStep];
        if (!activeStep && this.onBoardingSteps[0]) {
            return this.onBoardingSteps[0].url;
        }
        return activeStep.url;
    }

    public getCurrentOnboardingQueryParams(routeSnapshot: ActivatedRouteSnapshot): any {
        const planId = routeSnapshot.queryParamMap.get('plan');
        const couponId = routeSnapshot.queryParamMap.get('coupon');
        const addOnId = routeSnapshot.queryParamMap.get('addon');
        const cc = routeSnapshot.queryParamMap.get('cc');
        const yearlyPlanId = routeSnapshot.queryParamMap.get('yearly-plan');
        const yearlyCouponId = routeSnapshot.queryParamMap.get('yearly-coupon');
        const yearlyAddonId = routeSnapshot.queryParamMap.get('yearly-addon');
        const monthlyPlanId = routeSnapshot.queryParamMap.get('monthly-plan');
        const monthlyCouponId = routeSnapshot.queryParamMap.get('monthly-coupon');
        const monthlyAddonId = routeSnapshot.queryParamMap.get('monthly-addon');
        return {
            plan: planId,
            addon: addOnId,
            coupon: couponId,
            cc: cc,
            'yearly-plan': yearlyPlanId,
            'yearly-coupon': yearlyCouponId,
            'yearly-addon': yearlyAddonId,
            'monthly-plan': monthlyPlanId,
            'monthly-coupon': monthlyCouponId,
            'monthly-addon': monthlyAddonId
        };
    }


    public isAuthTokenExists() {
        const authToken = this.getAuthToken();
        return authToken !== null;
    }

    public isUserInfoExists() {
        const userInfo = localStorage.getItem(Constants.varUserInfo);
        if (userInfo === null) {
            return false;
        }
        return true;
    }
    
    public getLocalDate(date: string) {
        return moment.utc(date).local();
    }

    public encrypt(str: string) {
        const passPhrase = environment.aesBase64Cryptography.passPhrase;
        const secret = environment.aesBase64Cryptography.secret;
        return CryptoJS.AES.encrypt(str, passPhrase + secret).toString();
    }

    public decrypt(encryptedStr: string) {
        const passPhrase = environment.aesBase64Cryptography.passPhrase;
        const secret = environment.aesBase64Cryptography.secret;
        return CryptoJS.AES.decrypt(encryptedStr, passPhrase + secret).toString(CryptoJS.enc.Utf8);
    }

    public setFromLogout() {
        localStorage.setItem(Constants.varComingFromLogout, 'Yes');
    }

    public clearFromLogout() {
        localStorage.removeItem(Constants.varComingFromLogout);
    }

    public comingFromLogout() {
        if (localStorage.getItem(Constants.varComingFromLogout)) {
            return true;
        }
        return false;
    }

    // Two modal open closing process end

    public getDifferenceFromCurrentDate(date: string): number {
        return moment(date).diff(moment(), 'days');
    }

    public isNullOrZero(input: string): boolean {
        return input === null
            || typeof input === undefined
            || typeof input === 'undefined'
            || input === '0'
            || !input;
    }

    public isNullOrEmpty(input: any): boolean {
        return input === null
            || typeof input === undefined
            || typeof input === 'undefined'
            || !input;
    }

    public cleanPendingDisplayListIds() {
        localStorage.removeItem(Constants.pendingDisplayListIds);
    }

    public extractQueryParams(url: string) {
        if (url.indexOf('?') === -1) {
            return null;
        }

        // remove return url from
        url = (decodeURIComponent(url).replace('?returnUrl=/', ''));
        url = decodeURIComponent(url.toString().split('?')[1]);
        return url;
    }

    public getUnixTimeStamp() {
        return new Date().getTime();
    }

    getCurrentLocalTime() {
        return moment().local().format('HH:mm:ss');
    }

    public getAuthToken() {
        let authToken = localStorage.getItem(Constants.varAuthToken)
        if (!authToken) {
            authToken = sessionStorage.getItem(Constants.varAuthToken);
        }
        return authToken;
    }

    public removeAuthToken() {
        localStorage.removeItem(Constants.varAuthToken);
        sessionStorage.removeItem(Constants.varAuthToken);
    }


}
