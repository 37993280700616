import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginModel, ExternalLoginModel, SignUpModel, ResetPasswordModel, ValidateClaimModel, ForgotPasswordModel } from '../models';
import { environment } from '../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private http: HttpClient) { }

    private apiEndPoint = environment.apiBaseUrl + 'account/';

    signup(model: SignUpModel) {
        return this.http.post(this.apiEndPoint + `signup`, model);
    }

    login(model: LoginModel) {
        return this.http.post(this.apiEndPoint + 'login', model);
    }

    externalLogin(model: ExternalLoginModel) {
        return this.http.post(this.apiEndPoint + 'login-external', model);
    }

    getUpdatedToken() {
        return this.http.get(this.apiEndPoint + 'get-updated-token');
    }

    resetPassword(model: ResetPasswordModel) {
        return this.http.post(this.apiEndPoint + 'reset', model);
    }

    validateClaim(model: ValidateClaimModel) {
        return this.http.post(this.apiEndPoint + 'validate-reset', model);
    }

    recover(model: ForgotPasswordModel) {
        return this.http.post(this.apiEndPoint + 'recover', model);
    }
}
